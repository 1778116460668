import React from 'react';
import './header.scss';
import { websiteName } from '../static/pageContent';
import Logo from '../logo.png';

function Header() {
  return (
    <div className="headerContainer">
      <img src={Logo} alt='logo' />
      <h1 className="title">{websiteName}</h1>
    </div>
  );
}

export default Header;
