import React from "react";
import { Card } from "antd";
import "./noticeBoard.scss";
import { TwitterTimelineEmbed } from "react-twitter-embed";

function NoticeBoard() {
  return (
    <div className="newsContainer">
      <Card title="IIMC Updates" style={{ width: 400 }}>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="IIMC_India"
          options={{ height: 310 }}
          noHeader={true}
          noFooter={true}
          noScrollbar={true}
        />
      </Card>
    </div>
  );
}

export default NoticeBoard;
