import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';

export const socialPlatform = [
  {
    platform: 'Facebook',
    icon: FacebookOutlined,
    url: 'https://www.facebook.com/Alumni-Society-IIMC-100100902641259',
  },
  {
    platform: 'Twitter',
    icon: TwitterOutlined,
    url: 'https://twitter.com/asiimc_tweets',
  },
  {
    platform: 'Instagram',
    icon: InstagramOutlined,
    url: 'https://www.instagram.com/alumnisociety_iimc',
  },
  {
    platform: 'Youtube',
    icon: YoutubeOutlined,
    url: 'https://www.youtube.com/channel/UCU8fNdkKX3D-ZW_ikAf72vw',
  },
  {
    platform: 'LinkedIn',
    icon: LinkedinOutlined,
    url: 'https://www.linkedin.com/groups/12634647/',
  },
];
