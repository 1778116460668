import React from "react";
import { aboutUs } from "../static/pageContent";
import Footer from "./Footer";
import CommonPage from "./subSections/CommonPage";
import HomePage from "./subSections/HomePage";
import { Routes, Route } from "react-router-dom";
import "./content.scss";
import ContactPage from "./subSections/ContactPage";

function Content() {
  return (
    <div className="mainContainer">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about" element={<CommonPage content={aboutUs} />} />
        <Route path="contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default Content;
