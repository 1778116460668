import React from 'react';
import './homePage.scss';
import Activities from './Activities';
import MainActivity from './MainActivity';

function HomePage() {
  return (
    <div className="homePageContainer">
      <MainActivity />
      <Activities type={1} />
      <Activities type={2} />
    </div>
  );
}

export default HomePage;
