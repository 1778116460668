import React, { useContext } from 'react';
import './footer.scss';
import { List, Form, Input } from 'antd';
import { footerTitles, actionButton } from '../static/constant';
import { socialPlatform } from '../static/socialMedia';
import InfoContext from '../contexts/infoContext';

function Footer() {
  const info = useContext(InfoContext)
  return (
    <div className="footerContainer">
      <div className="socialPlatformContainer">
        <h1 className="footerTitle">{footerTitles.followUs}</h1>
        <List
          split={false}
          itemLayout="horizontal"
          dataSource={socialPlatform}
          renderItem={(el) => (
            <List.Item>
              <a tabIndex={5} target="_blank" href={el.url} className="socialLink" rel="noreferrer">
                <el.icon />
                <span>{el.platform}</span>
              </a>
            </List.Item>
          )}
        />
      </div>

      <div className="contactFormContainer">
        <h1 className="footerTitle">{footerTitles.contactUs}</h1>
        <Form>
          <Form.Item name="username">
            <Input tabIndex={6} placeholder="Enter your name" />
          </Form.Item>
          <Form.Item name="email">
            <Input tabIndex={7} placeholder="Enter your email" />
          </Form.Item>
          <Form.Item name="message">
            <Input.TextArea tabIndex={8} allowClear placeholder="Write your message" />
          </Form.Item>
          <Form.Item>
            <button onClick={() => info.showInfo()} tabIndex={9} className="submitButton" htmlType="submit">
              {actionButton.sendMessage}
            </button>
          </Form.Item>
        </Form>
      </div>
      
    </div>
  );
}

export default Footer;
