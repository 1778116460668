import React, { useContext } from 'react';
import { JobActivity, DiscussionActivity } from '../../static/activitiesContent';
import './activities.scss';
import InfoContext from '../../contexts/infoContext';
import Image from '../../assets/images/jobPortalImage.jpg';
import Library from '../../assets/images/library.jpg';

function Activities({ type }) {
  const info = useContext(InfoContext);

  return type === 1 ? (
    <div className="activityContainer light">
      <div className="info">
        <h1 className="title">{JobActivity.title}</h1>
        <p className="desc">{JobActivity.desc}</p>
        <button onClick={() => info.showInfo()} tabIndex={3} className="actionButton">{JobActivity.buttonText}</button>
      </div>
      <div className="image">
        <img alt="Job search representation"
          src={Image}
        />
      </div>
    </div>
  ) : (
    <div className="activityContainer dark">
      <div className="image">
        <img alt="demo"
          src={Library}
        />
      </div>
      <div className="info">
        <h1 className="title">{DiscussionActivity.title}</h1>
        <p className="desc">{DiscussionActivity.desc}</p>
        <button onClick={() => info.showInfo()} tabIndex={4} className="actionButton">{DiscussionActivity.buttonText}</button>
      </div>
    </div>
  );
}

export default Activities;
