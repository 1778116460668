export const JobActivity = {
  id: 1,
  title: 'A Job portal for journalists',
  desc: `Being part of a network helps us secure jobs. However, we also understand how this hinders providing equal opportunities to all those who may not be part of any network or have no exposure to the industry. So here we have a transparent two-way handshake portal that will allow you to advertise for jobs as well as apply for jobs. Plus, it will allow you to sponsor a journalist or raise money for your stories. Instead of getting around an opaque system of employment, we establish a system where equal opportunities are provided to all.`,
  image:
    'https://alumni.harvard.edu/sites/default/files/styles/large/public/feature/main_image/051118_farmer_paul_4411-1200x800.jpeg?itok=q_EmTmWV',
  buttonText: 'Read more'
};

export const DiscussionActivity = {
  id: 1,
  title: 'Discussion forum for IIMC Alumni',
  desc: 'This platform is exclusive to the alumni of Indian Institute of Mass Communication. The end of an academic term is not the end of academics. Our time at IIMC campus is over, but with this platform we will live and cherish again as IIMCians. Do you remember the last few words of a discussion at IIMC that was left unfinished? We go back to where we left off...',
  image:
    'https://alumni.harvard.edu/sites/default/files/styles/large/public/feature/main_image/051118_farmer_paul_4411-1200x800.jpeg?itok=q_EmTmWV',
  buttonText: 'Read more'
};
