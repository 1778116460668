export const routes = [
    {
      route: '',
      title: 'Home',
    },
    {
      route: 'about',
      title: 'About Us',
    },
    {
      route: 'contact',
      title: 'Contact Us',
    },
  ];