export const aboutUs = {
  title: 'About Us',
  desc: 'Welcome to the official website of the Alumni Society of Indian Institute of Mass Communication. We are a group of IIMC alumni from different campuses, departments and batches. We have come together to form a union. Apart from registering a permanent relationship with an institute, when we move out from an institute, we also inherit the responsibility of carrying the name of our institution with us. Indian Institute of Mass Communication will be permanently associated with our name. The purpose of creating this platform is to re-establish a channel of communication which is usually lost when we move out of our educational institutions. Therefore, we will endeavor to build a strong network of alumni and promote the lessons learned in our institution.',
  buttonText: 'Read More',
};

export const contactUs = {
  title: 'Contact Us',
  desc: 'Feel free to email your queries at alumnisocietyiimc@gmail.com',
  buttonText: 'Read More',
};

export const websiteName = 'Alumni Society of Indian Institute of Mass Communication';

export const mainContent = {
  title:
    'Building a network of concerned IIMCians...',
  buttonText: 'Register',
};
