import React from 'react';
import './navBar.scss';
import { Link } from 'react-router-dom';
import { routes } from '../../static/routes';

function NavBar() {
  return (
    <div className="navBarContainer">
      {routes.map((item) => (
        <Link to={item.route}>
          <span tabIndex={1} className="navButton">
            {item.title}{' '}
          </span>
        </Link>
      ))}
        <span tabIndex={1} className="navButton">
          <a href='http://iimc.nic.in/' target='_blank' rel='noreferrer'>IIMC</a>
        </span>
    </div>
  );
}

export default NavBar;
