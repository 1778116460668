import React from 'react';
import './commonPage.scss';

function CommonPage({ content }) {
  const { title, desc } = content;
  return (
    <div className="commonPageContainer">
      <h2 className="title">{title}</h2>
      <p className="description">{desc}</p>
    </div>
  );
}

export default CommonPage;
