import React, { useContext } from 'react';
import { mainContent } from '../../static/pageContent';
import './mainActivity.scss';
import NoticeBoard from './NoticeBoard';
import InfoContext from '../../contexts/infoContext';

const { title, buttonText } = mainContent;

function MainActivity() {
  const info = useContext(InfoContext);
  return (
    <div className="mainActivityContainer">
      <div className="mainContent">
        <span role="heading" aria-level={2} className="mainTitle">
          {title}
        </span>
        <button
          onClick={() => {
            info.showInfo();
          }}
          tabIndex={2}
          className="mainAction"
        >
          {buttonText}
        </button>
      </div>
      <div className="news">
        <NoticeBoard />
      </div>
    </div>
  );
}

export default MainActivity;
