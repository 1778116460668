import React, { useState } from 'react';
import { Modal } from 'antd';
import Content from './sections/Content';
import Header from './sections/Header';
import './mainLayout.scss';
import NavBar from './sections/subSections/NavBar';
import InfoContext from './contexts/infoContext';

function MainLayout() {
  const [isInfoVisible, setInfoVisible] = useState(false);

  const handleOk = () => {
    setInfoVisible(false);
  };

  const handleCancel = () => {
    setInfoVisible(false);
  };

  const showInfo = () => {
    setInfoVisible(true);
  };

  return (
    <InfoContext.Provider
      value={{ visible: isInfoVisible, showInfo: showInfo }}
    >
      <div className="mainLayoutContainer">
        <div className="fixedHeader">
          <Header />
          <NavBar />
        </div>
        <Content />
        <Modal visible={isInfoVisible} onOk={handleOk} onCancel={handleCancel}>
          <p>Coming soon...</p>
          <p>
            Please feel free to submit your queries{" "}
            <a
              href="https://forms.gle/sann6d65orRBRYue8"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </p>
          <p>
          Alumni are encouraged to add their details to the Alumni Directory{" "}
            <a
              href="https://forms.gle/6VUQyAL48rx6aSeD7"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </p>
        </Modal>
        <div className="footerSignature">
          <p> Copyright © 2022 - Alumni Society of IIMC</p>
        </div>
      </div>
    </InfoContext.Provider>
  );
}

export default MainLayout;
