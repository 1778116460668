import { Card } from "antd";
import React from "react";
import "./contactPage.scss";

const ContactPage = () => (
  <div className="contactPageContainer">
    <h2 className="title">Contact Us</h2>
    <div className="contactCardContainer">
      <Card hoverable title="Email Addresses" bordered={false}>
        <p>General queries - alumnisocietyiimc@gmail.com</p>
        <p>For business related queries - contact@asiimc.org</p>
      </Card>
    </div>
  </div>
);

export default ContactPage;
